import {
  StyledExternalPopularLink,
  StyledPopularLink,
} from "./PopularLinkContainer.module";
import { externalRoute } from "utils";

export interface StyledPopularLinkProps {
  width?: string;
  color?: string;
  bg?: string;
  pd?: string;
  maxWidth?: string;
  className?: string;
  to: string;
  children: any;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  external?: boolean;
  hoverOutlineColor?: string | null
  imageAlignmentHorizontal: string;
  imageAlignmentVertical: string;
}

const PopularLinkContainer = ({
  to,
  className = "",
  children,
  onMouseEnter,
  onMouseLeave,
  external,
  hoverOutlineColor,
  imageAlignmentHorizontal,
  imageAlignmentVertical,
  ...rest
}: StyledPopularLinkProps) => {
  return external ? (
    <StyledExternalPopularLink
      to=""
      href={externalRoute(to)}
      rel="external noopener noreferrer"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      hoverOutlineColor={hoverOutlineColor}
      imageAlignmentHorizontal={imageAlignmentHorizontal}
      imageAlignmentVertical={imageAlignmentVertical}
      className={`${className}`}
      {...rest}
    >
      {children}
    </StyledExternalPopularLink>
  ) : (
    <StyledPopularLink
      to={to}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      hoverOutlineColor={hoverOutlineColor}
      className={`${className}`}
      imageAlignmentHorizontal={imageAlignmentHorizontal}
      imageAlignmentVertical={imageAlignmentVertical}
      {...rest}
    >
      {children}
    </StyledPopularLink>
  );
};

export default PopularLinkContainer;
