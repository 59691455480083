import styled from "styled-components";
import {desktop, minDesktop, minTablet} from "../../../theme/variables";
import { StyledLayoutContainer } from "../Section/Section.module";
import FlexBox from "../FlexBox/FlexBox";

export const StyledHero = styled("div")`
  min-height: 12.5rem;
  padding-inline: 0;
`;

export const HeroContent = styled(StyledLayoutContainer)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-inline: 0;
  max-width: 1440px;

  @media ${desktop} {
    flex-wrap: nowrap;
  }

  * {
    color: #000;
  }
  div {
    h1 {
      color: #000000;
      @media screen and (max-width: ${minTablet - 1}px) {
        font-size: 3rem;
        line-height: 3rem;
      }
      @media screen and (min-width: ${minTablet}px) {
        font-size: 5rem;
        line-height: 4.75rem;
      }
      
    }
  }

  > :first-child {
    margin-right: 4rem;
  }
`;

export const HeroText = styled(FlexBox)`
  div {
    h1 {
      color: #000000;
      @media screen and (max-width: ${minTablet - 1}px) {
        font-size: 3rem;
        line-height: 3rem;
      }
      @media screen and (min-width: ${minTablet}px) {
        font-size: 5rem;
        line-height: 5rem;
      }

    }
  }
`;


