import { useState, useEffect } from "react";
import FlexBox from "components/layouts/FlexBox/FlexBox";
import PopularLinkContainer from "../PopularLinkContainer/PopularLinkContainer";
import Title from "components/typography/Title/Title";
import Body from "components/typography/Body/Body";
import Image from "components/layouts/Image/Image";
import { PopularLinkProps } from "../../../interfaces/PopularLinks";
import {StyledHoverBorder, StyledPopularLinkImageContainer} from "../PopularLinkContainer/PopularLinkContainer.module";

const AnimationTile = ({
  staticBackgroundColor,
  hover1BackgroundColor,
  hover2BackgroundColor,
  hover3BackgroundColor,
  callout,
  hoverOutlineColor1,
  hoverOutlineColor2,
  hoverOutlineColor3,
  hoverImage1Asset,
  hoverImage2Asset,
  hoverImage3Asset,
  baseImageAsset,
  imageAlignmentHorizontal,
  imageAlignmentVertical,
  linkUrl,
  title,
  external,
}: PopularLinkProps) => {
  let images = [baseImageAsset];
  if (
    baseImageAsset &&
    !hoverImage1Asset &&
    !hoverImage2Asset &&
    !hoverImage3Asset
  ) {
    images = [baseImageAsset];
  } else if (
    baseImageAsset &&
    hoverImage1Asset &&
    !hoverImage2Asset &&
    !hoverImage3Asset
  ) {
    images = [baseImageAsset, hoverImage1Asset];
  } else if (
    baseImageAsset &&
    hoverImage1Asset &&
    hoverImage2Asset &&
    !hoverImage3Asset
  ) {
    images = [baseImageAsset, hoverImage1Asset, hoverImage2Asset];
  } else if (
    baseImageAsset &&
    hoverImage1Asset &&
    hoverImage2Asset &&
    hoverImage3Asset
  ) {
    images = [
      baseImageAsset,
      hoverImage1Asset,
      hoverImage2Asset,
      hoverImage3Asset,
    ];
  }

  const [activeImage, setActiveImage] = useState<number>(0);
  const [mouseEntered, setMouseEntered] = useState<boolean>(false);
  const [hoverOutlineColor, setHoverOutlineColor] = useState<string | null>(null);
  const [backgroundColor, setBackgroundColor] = useState<string>();
  
  useEffect(() => {
    const imageInterval = setInterval(() => {
      if (images.length > 1) {
        setActiveImage(images[activeImage + 1] ? activeImage + 1 : 1);
      }
    }, 1200);
    if (!mouseEntered) {
      clearInterval(imageInterval);
    }
    return () => {
      clearInterval(imageInterval);
    };
  }, [mouseEntered, activeImage]);
  
  useEffect(() => {
    
    if (activeImage === 1) {
      if (hoverOutlineColor1 && hover1BackgroundColor) {
        setBackgroundColor(hover1BackgroundColor);
        setHoverOutlineColor(hoverOutlineColor1);
      } else if (hoverOutlineColor1 && !hover1BackgroundColor) {
        setHoverOutlineColor(hoverOutlineColor1);
        setBackgroundColor(staticBackgroundColor);
      } else if (!hoverOutlineColor1 && hover1BackgroundColor) {
        setBackgroundColor(hover1BackgroundColor);
        setHoverOutlineColor(null);
      } else {
        setBackgroundColor(staticBackgroundColor);
        setHoverOutlineColor(null);
      }
    } else if (activeImage === 2) {
      if (hoverOutlineColor2 && hover2BackgroundColor) {
        setBackgroundColor(hover2BackgroundColor);
        setHoverOutlineColor(hoverOutlineColor2);
      } else if (hoverOutlineColor2 && !hover2BackgroundColor) {
        setHoverOutlineColor(hoverOutlineColor2);
        setBackgroundColor(staticBackgroundColor);
      } else if (!hoverOutlineColor2 && hover2BackgroundColor) {
        setBackgroundColor(hover2BackgroundColor);
        setHoverOutlineColor(null);
      } else {
        setBackgroundColor(staticBackgroundColor);
        setHoverOutlineColor(null);
      }
    } else if (activeImage === 3) {
      if (hoverOutlineColor3 && hover3BackgroundColor) {
        setBackgroundColor(hover3BackgroundColor);
        setHoverOutlineColor(hoverOutlineColor3);
      } else if (hoverOutlineColor3 && !hover3BackgroundColor) {
        setHoverOutlineColor(hoverOutlineColor3);
        setBackgroundColor(staticBackgroundColor);
      } else if (!hoverOutlineColor3 && hover3BackgroundColor) {
        setBackgroundColor(hover3BackgroundColor);
        setHoverOutlineColor(null);
      } else {
        setBackgroundColor(staticBackgroundColor);
        setHoverOutlineColor(null);
      }
    } else {
      setBackgroundColor(staticBackgroundColor);
      setHoverOutlineColor(null);
    }
  }, [activeImage]);
  
  
  useEffect(() => {
  }, [hoverOutlineColor]);

  const onMouseEnter = () => {
    if (images.length > 1)
    {
      setActiveImage(1);
      // startCountHandler();
    }

    setMouseEntered(true);
  };

  const onMouseLeave = () => {
    if (images.length > 1) {
      setActiveImage(0);
    }
    setMouseEntered(false);
    // stopCountHandler();
  };
  
  return (
    <PopularLinkContainer
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      to={linkUrl}
      bg={backgroundColor}
      external={external}
      hoverOutlineColor={hoverOutlineColor}
      imageAlignmentHorizontal={imageAlignmentHorizontal}
      imageAlignmentVertical={imageAlignmentVertical}
    >
      <FlexBox row justify="space-between" gap="1rem">
        <Title size="small" bold pd="1.5rem 0 0 1.5rem">
          {title}
        </Title>
        <Title
          bold
          size="small"
          color="#EE0000"
          pd="1.5rem 1.5rem 0 0"
        >
          {callout}
        </Title>
      </FlexBox>
      {(baseImageAsset || hoverImage1Asset || hoverImage2Asset || hoverImage3Asset) && (
        <FlexBox className="image-container">
            {baseImageAsset && (
              <StyledPopularLinkImageContainer
                imageAlignmentHorizontal={imageAlignmentHorizontal}
                imageAlignmentVertical={imageAlignmentVertical}
                isActive={mouseEntered && activeImage === 0}
                justify={imageAlignmentHorizontal === "left" ? "start" : imageAlignmentHorizontal === "right" ? "end" : "center"} align={imageAlignmentVertical === "top" ? "start" : imageAlignmentVertical === "bottom" ? "end" : "center"} row>
                <Image
                  popularLink
                  className={`${activeImage === 0 ? "active" : ""}`}
                  src={baseImageAsset ? baseImageAsset : ""}
                />
              </StyledPopularLinkImageContainer>
            )}
            {hoverImage1Asset && (
              <StyledPopularLinkImageContainer
                imageAlignmentHorizontal={imageAlignmentHorizontal}
                imageAlignmentVertical={imageAlignmentVertical}
                isActive={activeImage === 1}
                justify={imageAlignmentHorizontal === "left" ? "start" : imageAlignmentHorizontal === "right" ? "end" : "center"} align={imageAlignmentVertical === "top" ? "start" : imageAlignmentVertical === "bottom" ? "end" : "center"} row>
                <Image
                  popularLink
                  className={`${activeImage === 1 ? "active" : ""}`}
                  src={hoverImage1Asset ? hoverImage1Asset : ""}
                />
              </StyledPopularLinkImageContainer>
            )}
            {hoverImage2Asset && (
              <StyledPopularLinkImageContainer
                imageAlignmentHorizontal={imageAlignmentHorizontal}
                imageAlignmentVertical={imageAlignmentVertical}
                isActive={activeImage === 2}
                justify={imageAlignmentHorizontal === "left" ? "start" : imageAlignmentHorizontal === "right" ? "end" : "center"} align={imageAlignmentVertical === "top" ? "start" : imageAlignmentVertical === "bottom" ? "end" : "center"} row>
                <Image
                  popularLink
                  className={`${activeImage === 2 ? "active" : ""}`}
                  src={hoverImage2Asset ? hoverImage2Asset : ""}
                />
              </StyledPopularLinkImageContainer>
            )}
            {hoverImage3Asset && (
              <StyledPopularLinkImageContainer
                imageAlignmentHorizontal={imageAlignmentHorizontal}
                imageAlignmentVertical={imageAlignmentVertical}
                isActive={activeImage === 3}
                justify={imageAlignmentHorizontal === "left" ? "start" : imageAlignmentHorizontal === "right" ? "end" : "center"} align={imageAlignmentVertical === "top" ? "start" : imageAlignmentVertical === "bottom" ? "end" : "center"} row>
                <Image
                  popularLink
                  className={`${activeImage === 3 ? "active" : ""}`}
                  src={hoverImage3Asset ? hoverImage3Asset : ""}
                />
              </StyledPopularLinkImageContainer>
            )}
        </FlexBox>
      )}
      {(mouseEntered && hoverOutlineColor) && (
        <StyledHoverBorder hoverOutlineColor={hoverOutlineColor} />
      )}
    </PopularLinkContainer>
  );
};

export default AnimationTile;
