import { useState, useEffect } from "react";
import {
  Modal,
  IconButton,
  Button,
  Line,
  Title,
  Body,
  FlexBox,
  Image,
  TextButton,
  Micro,
} from "components";
import {
  formatFileSize,
  getFileCurrentVersion,
  getFileName,
  openFileDownload,
} from "../../utils/assetFiles";
import {
  AssetFile,
  AssetFileGroup,
  withVDSManagerProps,
  DownloadOptionsModalProps,
} from "interfaces";
import { ApprovalStatus } from "../../interfaces/Asset";
import { StyledModalWrapper } from "./DownloadOptionsModal.module";
import {
  useLazyGetAssetDownloadUrlQuery,
  useLazyGetFileDownloadUrlQuery,
  useLazyGetFileGroupDownloadUrlQuery,
} from "../../services/api/api.slice";
import UsageRightsModal from "../usageRightsModal/UsageRightsModal";
import { withVDSManager } from "@vds/utilities";
import { useDispatch } from "hooks/redux";
import { setNotification } from "services";

const DownloadOptionsModal = ({
  assetId,
  usageRightsCopy,
  usageRightsAccepted,
  groups,
  files,
  onClose,
  onComplete,
  userCanDownload,
  viewport = "",
}: DownloadOptionsModalProps & withVDSManagerProps) => {
  const dispatch = useDispatch();

  const isMobileThruTablet: boolean = [
    "mobile",
    "mobileLarge",
    "tablet",
  ].includes(viewport as string);

  const [rightsAccepted, setRightsAccepted] = useState<boolean>(
      usageRightsCopy && usageRightsAccepted !== undefined
        ? usageRightsAccepted
        : true
    ),
    updateUsageRights = (value: boolean) => {
      setRightsAccepted(value);
    };

  const [usageRightsModalOpen, setUsageRightsModalOpen] =
      useState<boolean>(false),
    openUsageRightsModal = () => {
      setUsageRightsModalOpen(true);
    },
    completeUsageRightsModal = () => {
      updateUsageRights(true);
      setUsageRightsModalOpen(false);
      callGetFileUrl(fileVersionId);
    },
    closeUsageRightsModal = () => {
      setUsageRightsModalOpen(false);
    };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleComplete = () => {
    if (onComplete) {
      onComplete();
    }
  };

  const formatGroupFileSize = (files: AssetFile[]): string => {
    return formatFileSize(calculateGroupFileSize(files));
  };

  const calculateGroupFileSize = (files: AssetFile[]): number => {
    let totalSize = 0;

    for (const file of files) {
      const currentVersion = getFileCurrentVersion(file);

      totalSize += currentVersion?.sizeInBytes || 0;
    }

    return totalSize;
  };

  const [fileVersionId, setFileVersionId] = useState<string>("");

  const [
    getAssetUrl,
    {
      data: assetResult,
      isError: assetError,
      error: assetErrorMsg,
      isFetching: assetUrlFetching,
    },
  ] = useLazyGetAssetDownloadUrlQuery();
  const [
    getFileUrl,
    {
      data: fileResult,
      isError: fileError,
      error: fileErrorMsg,
      isFetching: fileUrlFetching,
    },
  ] = useLazyGetFileDownloadUrlQuery();
  const [
    getGroupUrl,
    {
      data: groupResult,
      isError: groupError,
      error: groupErrorMsg,
      isFetching: groupUrlFetching,
    },
  ] = useLazyGetFileGroupDownloadUrlQuery();

  const isFetching = assetUrlFetching || fileUrlFetching || groupUrlFetching;

  const formatAllFileSize = (): string => {
    if (groups === null) {
      return "";
    }
    let totalSize = 0;
    for (let x = 0; x < groups.length; x++) {
      totalSize += calculateGroupFileSize(groups[x].files);
    }
    return formatFileSize(totalSize);
  };

  const callGetFileUrl = (fileVersionId: string) => {
    getFileUrl({ assetId, fileVersionId })
      .unwrap()
      .catch((error: any) => {
        dispatch(
          setNotification({
            type: "error",
            message: "Could not get asset file url.",
          })
        );
        console.error(error);
      });
  };

  const callGetFileGroupUrl = (fileVersionId: string) => {
    getGroupUrl({ assetId, fileVersionId })
      .unwrap()
      .catch((error: any) => {
        dispatch(
          setNotification({
            type: "error",
            message: "Could not get asset file group url.",
          })
        );
        console.error(error);
      });
  };

  const callGetAssetUrl = () => {
    getAssetUrl(assetId)
      .unwrap()
      .catch((error: any) => {
        dispatch(
          setNotification({
            type: "error",
            message: "Could not get asset url.",
          })
        );
        console.error(error);
      });
  };

  const onDownloadFile = (file: AssetFile) => () => {
    if (!file.currentVersionId) {
      return;
    }
    if (usageRightsCopy && usageRightsCopy.length > 0 && !rightsAccepted) {
      setFileVersionId(file.currentVersionId);
      openUsageRightsModal();
    } else {
      callGetFileUrl(file.currentVersionId);
    }
  };

  const onDownloadAsset = () => {
    if (usageRightsCopy && usageRightsCopy.length > 0 && !rightsAccepted) {
      openUsageRightsModal();
    } else {
      callGetAssetUrl();
    }
  };

  const onDownloadFileGroup = (file: AssetFile) => () => {
    if (!file.currentVersionId) {
      return;
    }
    if (usageRightsCopy && usageRightsCopy.length > 0 && !rightsAccepted) {
      setFileVersionId(file.currentVersionId);
      openUsageRightsModal();
    } else {
      callGetFileGroupUrl(file.currentVersionId);
    }
  };

  // DOWNLOADING A SINGLE FILE
  useEffect(() => {
    fileResult && openFileDownload(fileResult);

    // fileResult && handleComplete();
  }, [fileResult]);

  useEffect(() => {
    assetResult && openFileDownload(assetResult);

    assetResult && handleComplete();
  }, [assetResult]);

  useEffect(() => {
    groupResult && openFileDownload(groupResult);
    // groupResult && handleComplete();
  }, [groupResult]);

  useEffect(() => {
    if (assetError && assetErrorMsg) {
      dispatch(
        setNotification({
          type: "error",
          message: "Error downloading asset.",
        })
      );
      console.error(assetErrorMsg);
    }
  }, [assetError, assetErrorMsg]);

  useEffect(() => {
    if (fileError && fileErrorMsg) {
      dispatch(
        setNotification({
          type: "error",
          message: "Error downloading file.",
        })
      );
      console.error(fileErrorMsg);
    }
  }, [fileError, fileErrorMsg]);

  useEffect(() => {
    if (groupError && groupErrorMsg) {
      dispatch(
        setNotification({
          type: "error",
          message: "Error downloading group.",
        })
      );
      console.error(groupErrorMsg);
    }
  }, [groupError, groupErrorMsg]);

  return (
    <Modal
      testId="download-options-modal"
      open
      onClose={handleClose}
      disabled={isFetching}
    >
      <StyledModalWrapper>
        <Title bold size="large">
          <span>{userCanDownload ? "Download options" : "Files in asset"}</span>
        </Title>
        {userCanDownload && (
          <Body className="uimodal-info">
            Click individual files and packages to download or download all
            below.
          </Body>
        )}
        <div className="uimodal-rule">
          <Line />
        </div>

        {/*start: desktop version. Show if all files have not a single children files as a total*/}
        <FlexBox pd="1.5rem 0 0 0" className="uimodal-list">
          {/*Start uimodal-list*/}
          <ul className="uimodal-scroll scrollbar">
            {groups !== null &&
              groups.map((group: AssetFileGroup, index) => {
                return (
                  group.default &&
                  getFileCurrentVersion(group.default)?.approvalStatus ===
                    ApprovalStatus[1] && (
                    <li key={index} className="uimodal-item-group">
                      <ul>
                        {/* Group Heading  */}
                        <li className="uimodal-item">
                          <FlexBox row justify="space-between">
                            {/* Asset Image  */}
                            {group.default?.thumbnails?.small && (
                              <div
                                style={{ width: "13%" }}
                                className="hide-on-mobile"
                              >
                                <div className="uimodal-media uimodal-media-absolute">
                                  <Image
                                    className="uimodal-image"
                                    alt="Thumbnail htmlFor group: {group.name}"
                                    src={group.default?.thumbnails?.small!}
                                  />
                                </div>
                              </div>
                            )}
                            {/* Asset File Name  */}
                            <div
                              style={{
                                width: `${isMobileThruTablet ? "75%" : "60%"}`,
                              }}
                              className="download-link"
                            >
                              {userCanDownload && (
                                <TextButton
                                  size="large"
                                  onClick={onDownloadFileGroup(group.default)}
                                  disabled={isFetching}
                                >
                                  {group.name}
                                </TextButton>
                              )}

                              {!userCanDownload && (
                                <Body
                                  size="large"
                                  bold
                                  className="uimodal-item-title"
                                >
                                  {group.name}
                                </Body>
                              )}
                            </div>
                            {/* Asset Group Filesize  */}
                            <div
                              style={{ width: "12%" }}
                              className="text-align-right uimodal-item-info"
                            >
                              <Micro>
                                Total {formatGroupFileSize(group.files)}
                              </Micro>
                            </div>
                            {userCanDownload && (
                              <div
                                style={{ width: "fit-content%" }}
                                className="text-align-center download-icon"
                              >
                                <IconButton
                                  noHover
                                  name="download"
                                  onClick={onDownloadFileGroup(group.default)}
                                  disabled={isFetching}
                                />
                              </div>
                            )}
                          </FlexBox>
                        </li>
                        {/* End Group Heading  */}

                        {/*asset files */}
                        {group.files.map((file) => {
                          return (
                            file.currentVersionId && (
                              <li
                                key={file.currentVersionId}
                                className="uimodal-item"
                              >
                                <FlexBox row justify="space-between">
                                  {/* Empty Column  */}
                                  <div
                                    style={{ width: "13%" }}
                                    className="hide-on-mobile"
                                  ></div>
                                  {/* Asset File Name  */}
                                  <div
                                    style={{
                                      width: `${
                                        isMobileThruTablet ? "75%" : "60%"
                                      }`,
                                    }}
                                    className="download-link
                "
                                  >
                                    {userCanDownload && (
                                      <TextButton
                                        size="small"
                                        onClick={onDownloadFile(file)}
                                        disabled={isFetching}
                                      >
                                        {getFileName(file)}
                                      </TextButton>
                                    )}
                                    {!userCanDownload && (
                                      <Body size="small">
                                        {getFileName(file)}
                                      </Body>
                                    )}
                                  </div>
                                  {/* Asset Filesize  */}
                                  <div
                                    style={{ width: "12%" }}
                                    className="text-align-right uimodal-item-info"
                                  >
                                    <Micro>
                                      {formatFileSize(
                                        getFileCurrentVersion(file)?.sizeInBytes
                                      )}
                                    </Micro>
                                  </div>
                                  {userCanDownload && (
                                    <div
                                      style={{ width: "fit-content" }}
                                      className="text-align-center download-icon"
                                    >
                                      <IconButton
                                        noHover
                                        name="download"
                                        onClick={onDownloadFile(file)}
                                        disabled={isFetching}
                                      />
                                    </div>
                                  )}
                                </FlexBox>
                              </li>
                            )
                          );
                        })}

                        {/*end children files */}
                      </ul>
                    </li>
                  )
                );
              })}

            {files !== null && (
              <li className="uimodal-item-group">
                <ul>
                  {/* Group Heading  */}
                  <li className="uimodal-item">
                    <Body size="large" bold>
                      Non-grouped files
                    </Body>
                  </li>
                  {/* End Group Heading  */}

                  {files.map((file) => {
                    return (
                      file &&
                      getFileCurrentVersion(file)?.approvalStatus ===
                        ApprovalStatus[1] &&
                      file.currentVersionId && (
                        <li className="uimodal-item">
                          <FlexBox row justify="space-between">
                            {/* Empty Column  */}
                            <div
                              style={{ width: "13%" }}
                              className="hide-on-mobile"
                            ></div>
                            {/* Asset File Name  */}
                            <div
                              style={{
                                width: `${isMobileThruTablet ? "75%" : "60%"}`,
                              }}
                              className="download-link
          "
                            >
                              {userCanDownload && (
                                <TextButton
                                  size="small"
                                  onClick={onDownloadFile(file)}
                                  disabled={isFetching}
                                >
                                  {getFileName(file)}
                                </TextButton>
                              )}{" "}
                              {!userCanDownload && (
                                <Body size="small">{getFileName(file)}</Body>
                              )}
                            </div>
                            {/* Asset Filesize  */}
                            <div
                              style={{ width: "12%" }}
                              className="text-align-right uimodal-item-info"
                            >
                              <Micro>
                                {formatFileSize(
                                  getFileCurrentVersion(file)?.sizeInBytes
                                )}
                              </Micro>
                            </div>
                            {userCanDownload && (
                              <div
                                style={{ width: "5%" }}
                                className="text-align-center download-icon"
                              >
                                <IconButton
                                  noHover
                                  name="download"
                                  onClick={onDownloadFile(file)}
                                  disabled={isFetching}
                                />
                              </div>
                            )}
                          </FlexBox>
                        </li>
                      )
                    );
                  })}

                  {/*end single files */}
                </ul>
              </li>
            )}
          </ul>
          {/* End list of assets  */}
        </FlexBox>
        {/*End uimodal-list */}

        {/*Start Downloading All section */}
        <FlexBox
          row
          justify="space-between"
          className="layout uimodal-controls"
        >
          <div
            style={{ width: "50%" }}
            className="download-all
                "
          >
            {userCanDownload && (
              <FlexBox row gap="1.5rem" align="center" justify="flex-start">
                <Button
                  use="primary"
                  size="large"
                  onClick={onDownloadAsset}
                  disabled={isFetching}
                >
                  Download all
                </Button>
                <TextButton onClick={handleClose} disabled={isFetching}>
                  Cancel
                </TextButton>
              </FlexBox>
            )}
          </div>
          <div
            style={{ width: "50%" }}
            className="download-all
                 hide-on-mobile text-align-right info"
          >
            <Micro>Total {formatAllFileSize()}</Micro>
          </div>
        </FlexBox>
        {/*End Downloading All section */}
      </StyledModalWrapper>

      {usageRightsModalOpen && (
        <UsageRightsModal
          assetId={assetId}
          usageRightsCopy={usageRightsCopy}
          onComplete={completeUsageRightsModal}
          onClose={closeUsageRightsModal}
        />
      )}
    </Modal>
  );
};

export default withVDSManager(DownloadOptionsModal);
