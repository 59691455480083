import styled from "styled-components";
import { ImageProps } from "./Image";

export const StyledPopLinkImage = styled(
  ({...props }) => <img {...props} />
)<ImageProps>`
  max-width: 100%;
  max-height: 100%;
  display: block;

  opacity: 0;
  transition: opacity 600ms;

  width: ${(props) => (props.width ? props.width : "auto")};

  &.active {
    opacity: 1;
  }
  
`;

export const StyledImage = styled(
  ({ border, maxWidth, maxHeight, ...props }) => <img {...props} />
)<ImageProps>`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : "100%")};
  height: ${(props) => (props.height ? props.height : "auto")};

  width: ${(props) => (props.width ? props.width : "auto")};

  border: ${(props) => (props.border ? "1px solid #d8dada" : "none")};
  border-radius: ${(props) => (props.radius ? props.radius : "unset")};

  &.background {
    background-color: ${(props) =>
      props.$bgColor ? props.$bgColor : "#f6f6f6"};
    border-radius: 0.5rem;
  }

  object-fit: ${(props) => (props.fit ? `${props.fit} !important` : "")};
`;
