import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { StyledPopularLinkProps } from "./PopularLinkContainer";
import FlexBox from "../../layouts/FlexBox/FlexBox";

interface ImageContainer {
  imageAlignmentHorizontal?: string;
  imageAlignmentVertical?: string;
  isActive: boolean;
}

interface HoverBorderProps {
  hoverOutlineColor: string;
}

const baseLinkStyle = css`
  height: 20rem;
  display: block;
  position: relative;
  //background-color: #F3EDE0;
  //font-family: fonts.$display_bold;
  //font-weight: normal;
  text-decoration: none;
  transform: translateZ(0);
  transition: background-color 600ms, transform 600ms;
  -webkit-font-smoothing: subpixel-antialiased;
  border-radius: 1.5rem;
  overflow: hidden;
  & > div > div > div {
    color: #000000;
  }

  &:hover {
    transform: scale(1.025);
    transition: background-color 600ms, transform 600ms;
    text-decoration: none;
    & > div > div > div {
      color: #000000;
    }
  }

  &:focus {
    text-decoration: none;
    outline: none;
    & > div > div > div {
      color: #000000;
    }
  }
  .image-container {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
`;

export const StyledHoverBorder = styled("div")<HoverBorderProps>`
  border-radius: 1.5rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: ${(props) => (props.hoverOutlineColor !== null ? `0.625rem solid ${props.hoverOutlineColor}` : "none")};
`;

export const StyledPopularLink = styled(Link)<StyledPopularLinkProps>`
  ${baseLinkStyle}
  &:hover, &:focus {
    border: ${(props) => (props.hoverOutlineColor === null && (props.bg === "#ffffff" || props.bg === "#fff" || props.bg === "#FFFFFF" || props.bg === "#FFF") ? `0.5px solid #A7A7A7` : "none")};
  }
  
  border: ${(props) => (props.bg === "#ffffff" || props.bg === "#fff" || props.bg === "#FFFFFF" || props.bg === "#FFF" ? `0.5px solid #A7A7A7` : "none")};
  
  background-color: ${(props) => (props.bg ? props.bg : "#F3EDE0")};
  .image-container {
    height: 17rem;
    position: absolute;
    bottom: 0;
  }
`;

export const StyledPopularLinkImageContainer = styled(FlexBox)<ImageContainer>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: ${(props) => (props.imageAlignmentHorizontal === "left" ? "0" : "1.5rem")};
  padding-right: ${(props) => (props.imageAlignmentHorizontal === "right" ? "0" : "1.5rem")};
  padding-top: ${(props) => (props.imageAlignmentVertical === "top" ? "0" : "1.5rem")};
  padding-bottom: ${(props) => (props.imageAlignmentVertical === "bottom" ? "0" : "1.5rem")};
`;

export const StyledExternalPopularLink = styled.a<StyledPopularLinkProps>`
  ${baseLinkStyle}
  &:hover, &:focus {
    border: ${(props) => (props.hoverOutlineColor === null && (props.bg === "#ffffff" || props.bg === "#fff" || props.bg === "#FFFFFF" || props.bg === "#FFF") ? `0.5px solid #A7A7A7` : "none")};
  }

  border: ${(props) => (props.bg === "#ffffff" || props.bg === "#fff" || props.bg === "#FFFFFF" || props.bg === "#FFF" ? `0.5px solid #A7A7A7` : "none")};

  background-color: ${(props) => (props.bg ? props.bg : "#F3EDE0")};
  .image-container {
    height: 17rem;
    position: absolute;
    bottom: 0;
  }
`;
