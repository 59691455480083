import styled from "styled-components";
import { mobile, minMobileLarge, minTablet, minDesktop, zIndex } from "theme/variables";

export const StyledFooter = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  footer {
    display: block;
    max-width: 1440px;
    width: 100%;
    margin: auto auto 0;

    z-index: ${zIndex.notBottom};
    
    
    @media only screen and (max-width: ${minTablet - 1}px) {
      padding: 1rem;
    }
    
    @media only screen and (min-width: ${minTablet}px) {
      padding: 1rem 1.25rem;
    }
    
    @media only screen and (min-width: 1480px) {
      padding: 1rem 0;
    }
  }
 

  ul {
    list-style: none;
    padding: 0;
    display: block;

    @media only screen and (min-width: ${minMobileLarge}px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  @media only screen and (min-width: ${minMobileLarge}px) {
    li {
      + li {
        margin-left: 1rem;
      }
      &:last-of-type {
        display: block;
        width: 100%;
        margin: 2.875rem 0 0;
        & > div > p {
          font-size: 0.68rem;
          color: #ffffff;
        }
      }
    }
  }

  @media ${mobile} {
    li {
      display: block;
      + li {
        margin-left: 0rem;
      }
      &:last-of-type {
        display: block;
        margin: 2.875rem 0 0;

        & > div > p {
          font-size: 0.68rem;
          color: #ffffff;
        }
      }
    }
  }

  .footer-link > div > p {
    color: #ffffff;
    text-decoration: none;
    font-size: 0.68rem;

    @media only screen and (min-width: ${minMobileLarge}px) {
      &:hover {
        text-decoration: underline;
        color: #ffffff;
      }
    }
  }
`;
